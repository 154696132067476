//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      // hero: require('@/assets/images/sirona-226.webp'),
      // bud: require('@/assets/images/bud-home.webp'),
    };
  },
  computed: {
    companyName() {
      return this.$store.state.companyName;
    },
    features() {
      return this.$store.state.features;
    }
  },
  head() {
    return {
      title: this.$t('pages.home.seoTitle'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('pages.home.seoMetaDescription')
        }
      ]
    }
  }
};
